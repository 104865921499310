<template>
  <div>
    <AccountDetail />
    <div class="row">
      <div class="col-md-3">
        <OrderCreateSidebar :uuid="account.account_uuid" :activeProductData="activeProductData" />
      </div>
      <div class="col-md-9">
        <dissolution
          v-if="selectedProducts.includes('dissolution')"
          v-model="activeProductData.dissolution"
          :pricing="jurisdictionPricing.dissolution"
          :jurisdiction="jurisdiction"
          :serviceName="'dissolution'"
          :hasPermission="true"
          serviceDisplayName="Dissolution">
        </dissolution>
        <genericServiceCreate
          v-if="selectedProducts.includes('reinstatement')"
          v-model="activeProductData.reinstatement"
          :pricing="jurisdictionPricing.reinstatement"
          :jurisdiction="jurisdiction"
          :serviceName="'reinstatement'"
          :hasPermission="hasPermission('access:all-products')"
          serviceDisplayName="Reinstatement">
        </genericServiceCreate>
        <genericServiceCreate
          v-if="selectedProducts.includes('resignation')"
          v-model="activeProductData.resignation"
          :pricing="jurisdictionPricing.resignation"
          :jurisdiction="jurisdiction"
          :serviceName="'resignation'"
          :hasPermission="hasPermission('access:all-products')"
          serviceDisplayName="Resignation">
        </genericServiceCreate>
        <genericServiceCreate
          v-if="selectedProducts.includes('adjustment')"
          v-model="activeProductData.adjustment"
          :pricing="jurisdictionPricing.adjustment"
          :jurisdiction="jurisdiction"
          :serviceName="'adjustment'"
          :hasPermission="hasPermission('access:all-products')"
          serviceDisplayName="Adjustment">
        </genericServiceCreate>
        <otherCreate
          v-if="selectedProducts.includes('other')"
          v-model="activeProductData.other"
          :pricing="jurisdictionPricing.other"
          :jurisdiction="jurisdiction">
        </otherCreate>
        <registeredAgentServiceCreate
          v-if="selectedProducts.includes('registered_agent_service')"
          v-model="activeProductData.registeredAgentService"
          :pricing="jurisdictionPricing.registered_agent_service"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </registeredAgentServiceCreate>
        <changeOfRegisteredAgentCreate
          v-if="selectedProducts.includes('change_of_registered_agent')"
          v-model="activeProductData.changeOfRegisteredAgentService"
          :pricing="jurisdictionPricing.change_of_registered_agent"
          :jurisdiction="jurisdiction"
          :businessEntityType="businessEntityType"
          :filingType="filingType">
        </changeOfRegisteredAgentCreate>
        <worry-free-service-create
          v-if="selectedProducts.includes('worry_free_service')"
          v-model="activeProductData.worryFreeService"
          :pricing="jurisdictionPricing.worry_free_service"
          :jurisdiction="jurisdiction">
        </worry-free-service-create>
        <corp-amendment-create
          v-if="selectedProducts.includes('corp_amendment')"
          v-model="activeProductData.corpAmendment"
          :pricing="jurisdictionPricing.corp_amendment"
          :jurisdiction="jurisdiction"
          :wfgCreditBalance="wfgCreditBalance">
        </corp-amendment-create>
        <llc-amendment-create
          v-if="selectedProducts.includes('llc_amendment')"
          v-model="activeProductData.llcAmendment"
          :pricing="jurisdictionPricing.llc_amendment"
          :jurisdiction="jurisdiction"
          :wfgCreditBalance="wfgCreditBalance">
        </llc-amendment-create>
        <standardGoodStandingCreate
          v-if="selectedProducts.includes('standard_good_standing')"
          v-model="activeProductData.standardGoodStanding"
          :pricing="jurisdictionPricing.standard_good_standing"
          :jurisdiction="jurisdiction">
        </standardGoodStandingCreate>
        <sCorpDesignationCreate
          v-if="selectedProducts.includes('s_corp_designation')"
          v-model="activeProductData.scorpDesignation"
          :pricing="productPricing['**'].s_corp_designation"
          :jurisdiction="jurisdiction">
        </sCorpDesignationCreate>
        <llcAnnualReportCreate
          v-if="selectedProducts.includes('llc_annual_report')"
          v-model="activeProductData.llcAnnualReport"
          :pricing="jurisdictionPricing.llc_annual_report"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </llcAnnualReportCreate>
        <corpAnnualReportCreate
          v-if="selectedProducts.includes('corp_annual_report')"
          v-model="activeProductData.corpAnnualReport"
          :pricing="jurisdictionPricing.corp_annual_report"
          :jurisdiction="jurisdiction">
        </corpAnnualReportCreate>
        <operatingAgreementCreate
          v-if="selectedProducts.includes('operating_agreement')"
          v-model="activeProductData.operatingAgreement"
          :pricing="jurisdictionPricing.operating_agreement"
          :jurisdiction="jurisdiction">
        </operatingAgreementCreate>
        <websiteToolsCreate
          v-if="selectedProducts.includes('website_tools_bundle')"
          v-model="activeProductData.websiteTools"
          :pricing="websiteToolsBundlePricing()"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </websiteToolsCreate>
        <domainRegistrationCreate
          v-if="selectedProducts.includes('domain_name_reg')"
          v-model="activeProductData.domainRegistration"
          :pricing="jurisdictionPricing.domain_name_reg"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </domainRegistrationCreate>
        <domainPrivacyCreate
          v-if="selectedProducts.includes('domain_name_privacy')"
          v-model="activeProductData.domainPrivacy"
          :pricing="jurisdictionPricing.domain_name_privacy"
          :jurisdiction="jurisdiction">
        </domainPrivacyCreate>
        <basicEmailCreate
          v-if="selectedProducts.includes('basic_email')"
          v-model="activeProductData.basicEmail"
          :pricing="jurisdictionPricing.basic_email"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </basicEmailCreate>
        <staticWebsite
          v-if="selectedProducts.includes('static_website')"
          v-model="activeProductData.staticWebsite"
          :pricing="jurisdictionPricing.static_website"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </staticWebsite>
        <planCreate
          v-if="selectedProducts.includes('plan')"
          v-model="activeProductData.plan"
          :jurisdiction="jurisdiction"
          :plans="plans[jurisdiction]"
          :supportedPlans="supportedPlans"
          :products="products"
          :coupons="coupons">
        </planCreate>
        <futureFileDateCreate
          v-if="selectedProducts.includes('future_file_date')"
          v-model="activeProductData.future_file_date"
          :pricing="jurisdictionPricing.future_file_date"
          :jurisdiction="jurisdiction">
        </futureFileDateCreate>
        <extraCreditsCreate
          v-if="selectedProducts.includes('extra_credits')"
          v-model="activeProductData.extra_credits"
          :pricing="jurisdictionPricing.extra_credits"
          :jurisdiction="jurisdiction">
        </extraCreditsCreate>
        <wfgInitialReportCreate
          v-if="selectedProducts.includes('wfg_initial_report')"
          v-model="activeProductData.wfg_initial_report"
          :pricing="jurisdictionPricing.wfg_initial_report"
          :jurisdiction="jurisdiction"
          :businessEntityType="businessEntityType">
        </wfgInitialReportCreate>
        <dbaEntityCreate
          v-if="selectedProducts.includes('dba_entity')"
          v-model="activeProductData.dba_entity"
          :pricing="jurisdictionPricing.dba_entity"
          :jurisdiction="jurisdiction"
          :activeProductData="activeProductData">
        </dbaEntityCreate>
        <dbaComplianceCreate
          v-if="selectedProducts.includes('dba_compliance')"
          v-model="activeProductData.dba_compliance"
          :pricing="jurisdictionPricing.dba_compliance"
          :jurisdiction="jurisdiction"
          :activeProductData="activeProductData">
        </dbaComplianceCreate>
        <taxServiceCreate
          v-if="selectedProducts.includes('tax_filing')"
          v-model="activeProductData.tax_filing"
          :pricing="jurisdictionPricing.tax_filing[businessEntityType]"
          :jurisdiction="jurisdiction"
          :activeProductData="activeProductData"
          :serviceName="'tax_filing'"
          :serviceDisplayName="'Tax Filing ' + '(' + businessEntityType.toUpperCase() + ')'">
        </taxServiceCreate>
        <taxServiceCreate
          v-if="selectedProducts.includes('audit_protection')"
          v-model="activeProductData.audit_protection"
          :pricing="jurisdictionPricing.audit_protection"
          :jurisdiction="jurisdiction"
          :activeProductData="activeProductData"
          :serviceName="'audit_protection'"
          :serviceDisplayName="'Audit Protection'">
        </taxServiceCreate>
        <taxServiceCreate
          v-if="selectedProducts.includes('tax_payment_assistance')"
          v-model="activeProductData.tax_payment_assistance"
          :pricing="jurisdictionPricing.tax_payment_assistance"
          :jurisdiction="jurisdiction"
          :activeProductData="activeProductData"
          :serviceName="'tax_payment_assistance'"
          :serviceDisplayName="'Tax Payment Assistance'">
        </taxServiceCreate>
        <rushCreate
          v-if="selectedProducts.includes('rush')"
          v-model="activeProductData.rush"
          :jurisdiction="jurisdiction"
          :pricing="jurisdictionPricing.rush">
        </rushCreate>
        <expediteCreate
          v-if="selectedProducts.includes('expedite')"
          v-model="activeProductData.expedite"
          :jurisdiction="jurisdiction"
          :pricing="jurisdictionPricing.expedite">
        </expediteCreate>
        <nyPublicationCreate
          v-if="selectedProducts.includes('ny_publication')"
          v-model="activeProductData.ny_publication"
          :pricing="jurisdictionPricing.ny_publication"
          :jurisdiction="jurisdiction"
          :activeProductData="activeProductData"
          :serviceName="'ny_publication'"
          :serviceDisplayName="'NY Publication'"
          :filingType="filingType"
          :stateFee="getStateFee()">
        </nyPublicationCreate>
        <corporateDocumentsCreate
          v-if="selectedProducts.includes('corporate_docs')"
          v-model="activeProductData.corporateDocuments"
          :pricing="jurisdictionPricing.corporate_docs"
          :jurisdiction="jurisdiction">
        </corporateDocumentsCreate>
        <zenMoneyProCreate
          v-if="selectedProducts.includes('zb_money_pro')"
          v-model="activeProductData.zenMoneyPro"
          :pricing="zbMoneyProPricing()"
          :jurisdiction="jurisdiction"
          :coupons="coupons">
        </zenMoneyProCreate>
        <zenMoneyEssentialCreate
          v-if="selectedProducts.includes('zb_money_essential')"
          v-model="activeProductData.zenMoneyEssential"
          :pricing="zbMoneyEssentialPricing()"
          :jurisdiction="jurisdiction">
        </zenMoneyEssentialCreate>
      <businessKitCreate
        v-if="selectedProducts.includes('business_kit')"
        v-model="activeProductData.businessKit"
        :jurisdiction="jurisdiction">
      </businessKitCreate>
      <expenseTrackingWithTaxFilingCreate
        v-if="selectedProducts.includes('expense_tracking_with_tax_filing')"
        v-model="activeProductData.expenseTrackingWithTaxFiling"
        :pricing="pricingAndFeesForGroupAndService('expense_tracking_with_tax_filing', 'zenbusiness')"
        :jurisdiction="jurisdiction">
      </expenseTrackingWithTaxFilingCreate>
      <zenBankingPreferred
        v-if="selectedProducts.includes('zb_banking_preferred')"
        v-model="activeProductData.zenBankingPreferred"
        :pricing="zbBankingPreferredPricing()"
        :jurisdiction="jurisdiction"
        :coupons="coupons">
      </zenBankingPreferred>
      <einCreationCreate
        v-if="selectedProducts.includes('ein_creation')"
        v-model="activeProductData.einCreation"
        :pricing="jurisdictionPricing.ein_creation"
        :jurisdiction="jurisdiction"
        :domesticJurisdiction="domesticJurisdiction"
        :businessEntity="businessEntity">
      </einCreationCreate>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex Imports
import { mapActions, mapState, mapGetters } from 'vuex';

// Component Imports
import AccountDetail from '@/components/accounts/AccountDetail.vue';
import OrderCreateSidebar from './order-create-sidebar';

// Mixin Imports
import { hasPermissionMixin } from '@/components/mixins';

// Product Imports
import basicEmailCreate from './products/basic-email';
import businessKitCreate from './products/business-kit';
import changeOfRegisteredAgentCreate from './products/change-of-registered-agent';
import corpAmendmentCreate from './products/corp-amendment';
import corpAnnualReportCreate from './products/corp-annual-report';
import corporateDocumentsCreate from './products/corporate-documents';
import dbaComplianceCreate from './products/dba-compliance';
import dbaEntityCreate from './products/dba-entity';
import dissolution from './products/dissolution';
import domainPrivacyCreate from './products/domain-privacy';
import domainRegistrationCreate from './products/domain-registration';
import einCreationCreate from './products/ein-creation';
import expediteCreate from './products/expedite';
import expenseTrackingWithTaxFilingCreate from './products/expense-tracking-with-tax-filing';
import extraCreditsCreate from './products/extra-credits';
import futureFileDateCreate from './products/future-file-date';
import genericServiceCreate from './products/generic-service';
import llcAmendmentCreate from './products/llc-amendment';
import llcAnnualReportCreate from './products/llc-annual-report';
import nyPublicationCreate from './products/ny-publication';
import operatingAgreementCreate from './products/operating-agreement';
import otherCreate from './products/other';
import planCreate from './products/plan';
import registeredAgentServiceCreate from './products/registered-agent-service';
import rushCreate from './products/rush';
import sCorpDesignationCreate from './products/s_corp_designation';
import standardGoodStandingCreate from './products/standard-good-standing';
import staticWebsite from './products/static-website';
import taxServiceCreate from './products/tax-service';
import websiteToolsCreate from './products/website-tools-bundle.vue';
import wfgInitialReportCreate from './products/wfg-initial-report';
import worryFreeServiceCreate from './products/worry-free-service';
import zenBankingPreferred from './products/zb-banking-preferred';
import zenMoneyEssentialCreate from './products/zen-money-essential';
import zenMoneyProCreate from './products/zen-money-pro';


export default {
  name: 'OrderCreate',

  mixins: [hasPermissionMixin],

  components: {
    AccountDetail,
    basicEmailCreate,
    businessKitCreate,
    changeOfRegisteredAgentCreate,
    corpAmendmentCreate,
    corpAnnualReportCreate,
    corporateDocumentsCreate,
    dbaComplianceCreate,
    dbaEntityCreate,
    dissolution,
    domainPrivacyCreate,
    domainRegistrationCreate,
    einCreationCreate,
    expenseTrackingWithTaxFilingCreate,
    expediteCreate,
    extraCreditsCreate,
    futureFileDateCreate,
    genericServiceCreate,
    llcAmendmentCreate,
    llcAnnualReportCreate,
    nyPublicationCreate,
    operatingAgreementCreate,
    OrderCreateSidebar,
    otherCreate,
    planCreate,
    registeredAgentServiceCreate,
    rushCreate,
    sCorpDesignationCreate,
    standardGoodStandingCreate,
    staticWebsite,
    taxServiceCreate,
    websiteToolsCreate,
    wfgInitialReportCreate,
    worryFreeServiceCreate,
    zenBankingPreferred,
    zenMoneyEssentialCreate,
    zenMoneyProCreate
  },

  computed: {
    ...mapState({
      account: (state) => state.accounts.account,
      businessEntities: (state) => state.businessEntities.businessEntities,
      creditBalances: (state) => state.businessEntities.creditBalances,
      jurisdictions: (state) => state.businessEntities.jurisdictions,
      jurisdictionPricing: (state) => state.orderCreate.jurisdictionPricing,
      productPricing: (state) => state.orderCreate.productPricing,
      jurisdiction: (state) => state.orderCreate.jurisdiction,
      businessEntityType: (state) => state.orderCreate.businessEntityType,
      businessEntity: (state) => state.orderCreate.businessEntity,
      plans: (state) => state.orderCreate.plans,
      coupons: (state) => state.orderCreate.coupons,
      products: (state) => state.orderCreate.allProducts,
      selectedProducts: (state) => state.orderCreate.selectedProducts,
      serviceFees: (state) => state.products.serviceFees
    }),
    ...mapGetters( {
      priceByGroupAndService: 'products/pricingByGroupAndService',
      accountUuid: 'accounts/getAccountUuid',
      isDevelopment: 'system/isDevelopment'
    }),

    wfgCreditBalance () {
      return this.creditBalances.find((cb) => cb.credit_balance_status === 'active' && cb.service_product_name_id === 'worry_free_service');
    },
    filingType() {
      const entityJurisdiction = this.jurisdictions.find((j) => j.jurisdiction_code === this.jurisdiction);
      return entityJurisdiction && entityJurisdiction.domestic_flag ? 'domestic' : 'foreign';
    },
    domesticJurisdiction() {
      const domesticJurisdiction = this.jurisdictions.find((jur) => jur.domestic_flag);
      return domesticJurisdiction ? domesticJurisdiction.jurisdiction_code : '';
    },
  },
  methods: {
    ...mapActions('orderCreate', [
      'fetchProductPricing',
      'resetOrderData',
      'clearProductFeesAndPrices',
    ]),
    ...mapActions('products', [
      'fetchServiceFees',
      'fetchServicePricing',
      'fetchServicePricingV2',
      'fetchPlanOptionsV2',
    ]),
    ...mapActions({
      fetchAccount: 'accounts/fetchAccount',
      fetchEntitiesByAccount: 'businessEntities/fetchEntitiesByAccount',
    }),
    zbMoneyProPricing() {
      // ZG Order creation pulls information from a number of places... `prices-and-fees` endpoint being one of them
      // prices-and-fees is outdated, and doesn't contain some new records
      // `v2/service-pricing` is the better one to use, but it doesn't match the format the mixin is expecting
      // (notably, if a service has 2+ different payment intervals, service-pricing returns 2+ records
      // while prices-and-fees sends one consolidated record

      // This converts between the service-pricing format and prices-and-fees so the mixin works correctly
      let moneyPricing = this.priceByGroupAndService('zenbusiness', 'zb_money_pro')
      return  {'price': 0, 'prices': {'1': moneyPricing['annual'].amount, '12': moneyPricing['monthly'].amount}, 'fees': 0, 'term': 12, 'period': 0}
    },
    zbMoneyEssentialPricing() {
      // Same behaviour as zb-money-pro, see conments on zbMoneyProPricing
      let moneyPricing = this.priceByGroupAndService('zenbusiness', 'zb_money_essential')
      return  {'price': moneyPricing.amount, 'fees': 0, 'term': 12, 'period': 1}
    },

    zbBankingPreferredPricing() {
      // Same behaviour as zb-money-pro, see conments on zbMoneyProPricing
      let bankingPricing = this.priceByGroupAndService('null', 'zb_banking_preferred')
      return  {'price': bankingPricing.amount, 'fees': 0, 'term': 12, 'period': 1}
    },

    websiteToolsBundlePricing() {
      // This is pricing placeholder for transOrRecurring mixin template tag
      // Actual pricing is obtained at the component level.
      return {'price': null, 'period': null}
    },

    pricingForGroupAndService(service, groupName) {
      let zbPricing = this.priceByGroupAndService(groupName, service)
      return !zbPricing ? 0 : zbPricing.amount;
    },
    feesForGroupAndService(service, groupName) {
      const zbPricing = this.serviceFees.filter(
        (pricing) => pricing.service === service && pricing.price_group === groupName
      );
      return !zbPricing.length ? 0 : zbPricing[0].amount;
    },
    pricingAndFeesForGroupAndService(service, groupName) {
      return {
        price: this.pricingForGroupAndService(service, 'zenbusiness'),
        fees: this.feesForGroupAndService(service, groupName)
      };
    },
    getStateFee() {
      const nyPubStatePricing = this.serviceFees.filter((sf) =>
        sf.service === 'ny_publication' && sf.state === this.jurisdiction
        && sf.filing_type === this.filingType && sf.county === 'STATE'
      )
      if (nyPubStatePricing.length > 0) {
        return parseFloat(nyPubStatePricing[0].amount)
      }
      return 0
    }
  },

  data() {
    return {
      activeProductData: {
        standardGoodStanding: null
      },
      supportedPlans: [],
    };
  },

  created() {
    this.fetchPlanOptionsV2().then((res) => {
      if (res.data?.results) {
        const supportedPlansRaw = res.data.results.map((plan) => {
          if (
            plan.related_plan_pricing.active_flag &&
            !plan.related_plan_pricing.deleted &&
            plan.can_be_ordered_zg
          ) {
            return plan.related_plan_pricing.service;
          }
        });
        this.supportedPlans = supportedPlansRaw;
        // Selectively add plans for dev environment
        if (this.isDevelopment) {
          const inDevPlans = [
            "starter_plan_v26",
            "pro_plan_v26",
            "premium_plan_v26",
          ];
          this.supportedPlans.push(...inDevPlans);
        }
      }
    });
    this.resetOrderData();
  },

  beforeMount() {
    this.fetchProductPricing();
    this.fetchServiceFees();
    this.fetchServicePricingV2();
  },

  beforeDestroy() {
    this.resetOrderData();
    this.clearProductFeesAndPrices();
  },

  mounted() {
    this.fetchAccount({ accountUuid: this.accountUuid });
    this.fetchEntitiesByAccount({ accountUuid: this.accountUuid });
  }

};

</script>

<style lang="scss" scoped>

</style>
