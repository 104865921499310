<template>
  <div>
    <div class="menu">
      <dt>Business Entity Name</dt>
      <dd class="wrap" v-if="businessEntity && businessEntity.business_entity_name">{{ businessEntity.business_entity_name }}</dd>
      <dd v-else></dd>
      <div v-if="order.order_data && order.order_data[0].created_by">
        <dt>Created By</dt>
        <dd class="wrap">{{ order.order_data[0].created_by }}</dd>
      </div>
      <div v-if="order.order_data && order.order_data[0].service_description">
        <dt>Service Description</dt>
        <dd>{{ order.order_data[0].service_description }}</dd>
      </div>
      <dt>Business Entity Type</dt>
      <dd v-if="businessEntity && businessEntity.business_entity_type" id="entity-type">{{ businessEntity.business_entity_type }}</dd>
      <dd v-else></dd>
      <dt>Jurisdiction</dt>
      <dd>{{ order.jurisdiction || 'unknown' }}</dd>
      <dt>Formation Status</dt>
      <dd id="entity-formation-status">{{ businessEntity.formation_status || 'unknown' }}</dd>
      <dt>Formed By</dt>
      <dd id="entity-formed-by">{{ businessEntity.formed_by || 'unknown' }}</dd>
      <dt>Created at</dt>
      <dd>{{ createdDatetimeDisplayString }}</dd>
      <dt>Last updated at</dt>
      <dd>{{ updatedDatetimeDisplayString }}</dd>
      <dt>Status</dt>
      <dd class="badge" v-bind:class="status">{{ order.order_status }}</dd>
      <dt>Fulfillment Level</dt>
      <dd class="badge" v-bind:class="fulfillmentLevel">{{ order.fulfillment_level }}</dd>
      <dt>API Version</dt>
      <dd>{{ order.api_version }}</dd>
      <dt>Salesforce</dt>
      <dd><a :href="opportunitySalesforceUrl" target="_blank">Opportunity</a></dd>
      <dt v-if="enableCouponStatusFeatureFlag && displayCoupon && couponStatus?.statusMessage">Coupon Status</dt>
      <dd v-if="enableCouponStatusFeatureFlag && displayCoupon && couponStatus?.statusMessage">{{ couponStatus.statusMessage }}</dd>

    </div>
    <div class="menu">
      <h3>Internal Links</h3>
      <h3><a href="" @click.prevent="handleAccountClick">Account</a></h3>
      <h3><a href="" @click.prevent="handleBusinessEntityClick">Business Entity</a></h3>
    </div>
    <div class="menu" v-if="!isUnisearch">
      <h3>External Links</h3>
      <h3><a href="mailto:partners@registeredagentsinc.com">Email Registered Agents Inc.</a></h3>
      <h3><a :href="jurisdictionSOSHome" target="_blank">{{ jurisdiction }} SOS Home</a></h3>
      <h3><a :href="jurisdictionSOSSearch" target="_blank">{{ jurisdiction }} SOS Search</a></h3>
      <h3><a href="https://sa.www4.irs.gov/modiein/individual/index.jsp" target="_blank">IRS EIN Assistant</a></h3>
    </div>
    <div v-if="!isCanceled && !isUnisearch" class="menu">
      <h3>Actions</h3>
      <div class="btn btn-info action" @click="handleNoteClick">Add Note</div>
      <div class="btn btn-info action" @click="handleGenerateSOOClick">Generate SOO (Courtney's Signature)</div>
      <div class="btn btn-info action" @click="handleResendRecieptClick">Resend Invoice</div>
      <div class="btn btn-info action" @click="handleResendWelcomeClick">Resend Welcome Email</div>
      <AutomationActions v-if="getOrderTasksWithAutomation"
        :automatedFiling="automatedFiling"
        :orderTasksWithAutomation="getOrderTasksWithAutomation"
      />
      <ZgButton
        v-if="!!orderSOI && onProd && isAutomationAdmin && jurisdiction === 'CA'"
        type="button"
        class="btn btn-info action"
        @click="handleAutomatedSOIAsTestClick"
        :disabled="isAutomationAdmin"
      >
        <template v-slot:title>
          <i class="fa fa-user-lock" v-if="isAutomationAdmin" />&nbsp;
          Start Automated SOI AS TEST
        </template>
      </ZgButton>
      <ZgButton
        v-if="!!orderSOI && isAutomationAdminOrProcessor && jurisdiction === 'CA'"
        :disabled="isSOIBtnDisabled"
        type="button"
        class="btn btn-info action"
        @click="handleAutomatedSOIClick"
      >
        <template v-slot:title>
          Start Automated SOI
        </template>
      </ZgButton>
      <div v-if="isValidAutomatedFiling(getAutomatedFiling(false))" class="btn btn-info action" @click="handleSyncAutomatedFilingClick">Sync Automated Filing</div>
      <div v-if="isValidAutomatedFiling(getAutomatedFiling(true)) && onProd" class="btn btn-info action" @click="handleSyncTestAutomatedFilingClick">Sync TEST Automated Filing</div>
      <div v-if="canGenerateFormation" class="btn btn-info action" @click="handleGenerateFormationClick">Generate Formation Documents</div>
      <div v-if="canGenerateSS4" class="btn btn-info action" @click="handleGenerateSS4Click">Generate SS4</div>
      <div class="btn btn-info action" @click="handleGenerateF2553Click">Generate Form 2553</div>
      <div v-if="canProcessCompletedOrder" class="btn btn-info action" @click="handleProcessCompletedOrderClick">Process Completed Order</div>
      <div class="btn btn-info action" @click="handleSendOrderToSalesforceClick">Send Order to Salesforce</div>
      <div v-if="canProcessCompletedOrder" class="btn btn-info action" @click="handleGeneratePartnerRequestsClick">Generate Partner Requests</div>
      <div v-if="numberOfActivePlansInOrder > 0" class="btn btn-warning action" @click="cancelForDowngradeClicked = true">Downgrade Plan in Order</div>
      <div v-else class="btn btn-warning action disabled" title="This order does not include any active non-starter plans, and so cannot be downgraded.">Downgrade Plan in Order</div>
      <div v-if="displayCoupon" class="btn btn-warning action"
           @click="openJourneyModal()"
           :class="{ disabled: !couponStatus.buttonStatus }"
        >Issue Plan Coupon</div>
    </div>
    <CancelForDowngradeModal v-if="cancelForDowngradeClicked"
                             @close="cancelForDowngradeClicked = false"
                             @canceled="refresh"
                             v-bind:uuid="uuid" />

    <CouponJourneyModal
      :isJourneyUpdateSuccess="isJourneyUpdateSuccess"
      :isJourneyUpdateSubmitting="isJourneyUpdateSubmitting"
      v-if="isJourneyModalOpen"
      @closeJourneyModal="closeJourneyModal"
      @handleCreateCouponJourney="handleCreateCouponJourney()"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import manifest from '../../manifest';
import { hasPermissionMixin, automatedFilingMixin, orderMixin } from '@/components/mixins';
import { AUTOMATED_SOI_ORDER_TASK_TYPES, ORDER_TASK_TYPES_ALLOWED_FOR_AUTOMATION } from '@/assets/constants/automations';
import sossearch from '@/assets/sossearch';
import soshome from '@/assets/soshome';
import CancelForDowngradeModal from './cancel-downgrade-modal';
import ZgButton from '../common/Generics/ZgButton.vue';
import AutomationActions from './AutomationActions.vue';
import { getClient } from "@/client/ldClient";
import { couponJourneyEnabled, couponJourneyStatusEnabled } from "@/assets/utils/feature-utils";
import CouponJourneyModal from "@/components/business-entities/CouponJourneyModal.vue";
import {getCouponStatus} from "@/assets/utils/coupons";

export default {
  name: 'OrderSideBar',

  components: {
    CancelForDowngradeModal,
    ZgButton,
    AutomationActions,
    CouponJourneyModal
  },

  mixins: [
    hasPermissionMixin,
    automatedFilingMixin,
    orderMixin
  ],

  props: ['uuid'],

  data() {
    return {
      cancelForDowngradeClicked: false,
      annualReportFilingTestSent: false,
      annualReportFilingRealSent: false,
      isJourneyModalOpen: false,
      isJourneyUpdateSuccess: false,
      isJourneyUpdateSubmitting: false,
      modalData: {},
      featureFlagClient: null,
      isFeatureEnabled: false,
      enableCouponJourneyFeatureFlag: false,
      promotionData: null,
      journeyStatusData: null,
      couponJourney: null,
      enableCouponStatusFeatureFlag: false,
    };
  },
  async created() {
    this.featureFlagClients = await getClient();
    this.enableCouponJourneyFeatureFlag = couponJourneyEnabled( this.featureFlagClients);
    this.enableCouponStatusFeatureFlag = couponJourneyStatusEnabled( this.featureFlagClients);
  },

  computed: {
    ...mapState('orders', ['order', 'canGenerateFormation']),
    ...mapState({
      businessEntity: (state) => state.orders.businessEntity,
      orderTasks: (state) => state.orders.orderTasks,
      documents: (state) => state.businessEntities.documents,
      automatedFiling: (state) => state.orders.sosStatus,
      subscriptionBillingItems: (state) => state.orders.subscriptionBillingItems
    }),
    ...mapGetters({
      getOrderTasksByTypeArr: 'orders/getOrderTasksByTypeArr',
      getAutomatedFiling: 'orders/getAutomatedFiling'
    }),
    couponStatus() {
      const proOrder = this.getProPlansInOrder;
      const couponStatus = getCouponStatus(this.couponJourney, this.promotionData, proOrder?.term_end_date);

      return couponStatus;
    },
    displayCoupon() {
      return this.hasProPlansInOrder > 0
        && this.businessEntity.formation_status ==='formed'
        && this.enableCouponJourneyFeatureFlag
    },
    getOrderTasksWithAutomation() {
      return this.getOrderTasksByTypeArr(ORDER_TASK_TYPES_ALLOWED_FOR_AUTOMATION);
    },
    fulfillmentLevel() {
      if (this.order.fulfillment_level === 'expedite') {
        return {
          'bg-warning': true
        };
      } if (this.order.fulfillment_level === 'rush') {
        return {
          'bg-danger': true
        };
      }
      return {
        'bg-info': true
      };
    },
    status() {
      if (this.order.order_status === 'incomplete') {
        return {
          'bg-secondary': true
        };
      } if (this.order.order_status === 'canceled') {
        return {
          'bg-danger': true
        };
      }
      return {
        'bg-info': true
      };
    },
    isCanceled() {
      return this.order.order_status === 'canceled';
    },
    isIncomplete() {
      return this.order.order_status === 'incomplete';
    },
    canAutomateReport() {
      return (
        (this.hasService("llc_annual_report") &&
          this.hasAutomatedPermission("llc_annual_report")) ||
        (this.hasService("corp_annual_report") &&
          this.hasAutomatedPermission("corp_annual_report"))
      );
    },
    orderSOI() {
      const SOIOrderTaskTypes = Object.keys(AUTOMATED_SOI_ORDER_TASK_TYPES);
      return this.orderTasks.find((ot) =>
        SOIOrderTaskTypes.includes(ot.order_task_type)
      );
    },
    canGenerateSS4() {
      return this.hasEinCreation;
    },
    canProcessCompletedOrder() {
      return this.hasPermission('access:developers');
    },
    hasEinCreation() {
      return this.hasService('ein_creation');
    },
    jurisdiction() {
      return this.order.jurisdiction;
    },
    jurisdictionSOSSearch() {
      return sossearch[this.order.jurisdiction];
    },
    jurisdictionSOSHome() {
      return soshome[this.order.jurisdiction];
    },
    opportunitySalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.order.opportunity_sf_id}/view`;
    },
    createdDatetimeDisplayString() {
      return moment(this.order.created_datetime).format('MMMM DD YYYY, h:mm:ss a');
    },
    updatedDatetimeDisplayString() {
      return moment(this.order.updated_datetime).format('MMMM DD YYYY, h:mm:ss a');
    },
    numberOfActivePlansInOrder() {
      return this.orderTasks.filter((ot) => ot.order_task_type.includes('plan')
        && !ot.order_task_type.includes('starter')
        && ot.order_task_status !== 'canceled').length;
    },
    isUnisearch() {
      return this.hasPermission('partner:unisearch');
    },
    allowAutomationRetry () {
      return this.isAutomationAdmin || (this.allowAutomatedFiling)
    },
    isSOIBtnDisabled() {
      return this.orderSOI.order_task_status != 'pending';
    },
    hasProPlansInOrder() {
      return this.orderTasks.filter((ot) =>
        ot.order_task_type.startsWith('pro_plan_v') &&
        !ot.order_task_type.includes('starter') &&
        ot.order_task_status !== 'canceled'
      ).length;
    },
    getProPlansInOrder() {
      return this.orderTasks.find((ot) =>
        ot.order_task_type.startsWith('pro_plan_v') &&
        ot.order_task_status === 'active'
      )
    }
  },
  methods: {
    ...mapActions({
      startAutomatedFiling: 'orders/startAutomatedFiling',
      setAlertMessage: 'setAlertMessage',
      fetchSubscriptionBillingItems: 'orders/fetchSubscriptionBillingItems',
      fetchOrderBillingItemPromotions: 'orders/fetchOrderBillingItemPromotions',
      fetchJourneyForAccount: 'businessEntities/fetchJourneyForAccount',
      createCouponJourney: 'businessEntities/createCouponJourney',
    }),
    handleAccountClick() {
      this.$router.push({ name: 'Account', params: { accountUuid: this.order.account_uuid } });
    },
    handleBusinessEntityClick() {
      this.$router.push({ name: 'BusinessEntity', params: { businessEntityUuid: this.order.business_entity_uuid } });
    },
    /**
     * handleGenerateSOOClick (Courtney's Signature)
     */
    handleGenerateSOOClick() {
      this.$store.dispatch('orders/generateSOO', { businessEntityUuid: this.order.business_entity_uuid });
    },
    handleGenerateSS4Click() {
      this.$store.dispatch('orders/generateSS4', { orderUuid: this.uuid, businessEntityUuid: this.order.business_entity_uuid });
    },
    handleGenerateF2553Click() {
      this.$store.dispatch('orders/generateF2553', { businessEntityUuid: this.order.business_entity_uuid });
    },
    handleResendRecieptClick() {
      this.$store.dispatch('orders/resendOrderReceipt', { uuid: this.uuid });
    },
    handleResendWelcomeClick() {
      this.$store.dispatch('orders/resendWelcomeEmail', { uuid: this.uuid });
    },
    handleAutomatedReportClick() {
      this.annualReportFilingRealSent = true
      const uuid = this.orderTasks.find(
        (ot) =>
          ot.order_task_type === "llc_annual_report" ||
          ot.order_task_type === "corp_annual_report"
      ).order_task_uuid;
      this.startAutomatedFiling({ uuid, test: false, reloadPage: true });
    },
    handleAutomatedReportAsTestClick() {
      this.annualReportFilingTestSent = true
      const uuid = this.orderTasks.find(
        (ot) =>
          ot.order_task_type === "llc_annual_report" ||
          ot.order_task_type === "corp_annual_report"
      ).order_task_uuid;
      this.startAutomatedFiling({ uuid, test: true, reloadPage: true });
    },
    handleAutomatedSOIClick() {
      if (this.orderSOI) {
        const uuid = this.orderSOI.order_task_uuid;
        this.startAutomatedFiling({
          uuid,
          test: false,
          reloadPage: true,
          filingType: AUTOMATED_SOI_ORDER_TASK_TYPES[this.orderSOI.order_task_type],
        });
      }
    },
    handleAutomatedSOIAsTestClick() {
      if (this.orderSOI) {
        const uuid = this.orderSOI.order_task_uuid;
        this.startAutomatedFiling({
          uuid,
          test: true,
          reloadPage: true,
          filingType: AUTOMATED_SOI_ORDER_TASK_TYPES[this.orderSOI.order_task_type],
        });
      }
    },
    handleSyncAutomatedFilingClick() {
      const automatedFiling = this.getAutomatedFiling(false)
      this.$store.dispatch('orders/syncAutomatedFiling', { uuid: automatedFiling.automated_filing_uuid });
    },
    handleSyncTestAutomatedFilingClick() {
      const automatedFiling = this.getAutomatedFiling(true)
      this.$store.dispatch('orders/syncAutomatedFiling', { uuid: automatedFiling.automated_filing_uuid });
    },
    handleGenerateFormationClick() {
      this.$store.dispatch('orders/generateFormation', { uuid: this.uuid });
    },
    handleNoteClick() {
      this.$router.push({ name: 'OrderNoteCreate', params: { uuid: this.order.order_uuid } });
    },
    handleProcessCompletedOrderClick() {
      this.$store.dispatch('orders/processCompletedOrder', { orderUuid: this.uuid });
    },
    handleSendOrderToSalesforceClick() {
      this.$store.dispatch('orders/sendOrderToSalesforce', { orderUuid: this.uuid });
    },
    handleGeneratePartnerRequestsClick() {
      this.$store.dispatch('orders/generatePartnerRequests', { orderUuid: this.uuid });
    },
    refresh() {
      this.cancelForDowngradeClicked = false;
      this.$store.dispatch('orders/fetchOrder', { uuid: this.uuid });
      this.$store.dispatch('orders/fetchOrderTasks', { uuid: this.uuid });
    },
    isValidAutomatedFiling(automatedFiling) {
      return automatedFiling === undefined ? false : (Object.keys(automatedFiling).length);
    },
    openJourneyModal() {
      this.isJourneyUpdateSuccess = true;
      this.isJourneyModalOpen = true;
    },
    closeJourneyModal() {
      this.isJourneyUpdateSubmitting = false
      this.isJourneyUpdateSuccess = true
      this.isJourneyModalOpen = false
    },

    async handleCreateCouponJourney() {
      this.isJourneyUpdateSubmitting = true;

      const proOrder = this.getProPlansInOrder;

      await this.fetchSubscriptionBillingItems({ uuid: proOrder.order_uuid } )

      const subscriptionBillingItems = this.subscriptionBillingItems;

      const businessEntityUuid = this.order.business_entity_uuid;
      const accountUuid = this.order.account_uuid;
      const orderBillingItemUuid = subscriptionBillingItems[0].uuid;
      const orderUuid = proOrder?.order_uuid;

      const payload = {
        journey: proOrder.order_task_type,
        businessEntityUuid,
        accountUuid,
        orderUuid,
        orderBillingItemUuid
      };

      await this.$store.dispatch('businessEntities/createCouponJourney', payload);
      this.isJourneyUpdateSuccess = true;
      await this.updateCouponStatus();
      this.closeJourneyModal();
    },
    async updateCouponStatus() {
      try {
        const proOrder = this.getProPlansInOrder

        if (proOrder) {
          const journeys = await this.fetchJourneyForAccount({ accountUuid: this.order?.account_uuid });
          this.couponJourney = journeys?.find(journey => journey.businessEntityUuid === this.order.business_entity_uuid && journey.slug === 'coupon_pro_plan_v1');
          if (this.couponJourney) {
            const subscriptionBillingItems = this.subscriptionBillingItems?.find(item => item.service === proOrder.order_task_type);

            if (subscriptionBillingItems) {
              this.promotionData = await this.fetchOrderBillingItemPromotions({
                orderBillingItemUuid: subscriptionBillingItems.uuid,
                commitToStore: false
              });
            }
          }
        }

      } catch (error) {
        console.error('Failed to update coupon status', error);
      }
    }
  },
  watch: {
    orderTasks: {
      async handler(tasks) {
        const proOrder = tasks?.find(task => task.order_task_type.startsWith('pro_plan_v'));

        if (proOrder && proOrder.order_uuid && this.order?.account_uuid) {

          const journeys = await this.fetchJourneyForAccount({ accountUuid: this.order?.account_uuid });
          const couponJourney = journeys.find(journey => journey.businessEntityUuid === this.businessEntity?.business_entity_uuid && journey.slug === 'coupon_pro_plan_v1')
          this.couponJourney = couponJourney;

          this.fetchSubscriptionBillingItems({ uuid: proOrder.order_uuid }).then(() => {
            const subscriptionBillingItems = this.subscriptionBillingItems?.find(item => item.service === proOrder.order_task_type)
            if (subscriptionBillingItems) {
              this.fetchOrderBillingItemPromotions({
                orderBillingItemUuid: subscriptionBillingItems.uuid,
                commitToStore: true
              }).then((promotionData) => {
                this.promotionData = promotionData;
              });
            } else {
              console.warn('No subscription billing items found for Pro Plan.');
            }
          });
        } else {
          console.warn('No Pro plan found .');
        }
      },
      immediate: true
    }
  }

};
</script>

<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 3px 0 #f8f8f8;
  }
  div .action {
    width: 100%;
    margin-top: 10px;
  }
  .badge {
    text-transform: capitalize;
  }
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
  #entity-type {
    text-transform: uppercase;
  }
  i {
    font-size: 10px;
  }
  .wrap {
    word-wrap: break-word;
  }
</style>
