import { render, staticRenderFns } from "./bof-filing-view.vue?vue&type=template&id=63890054&scoped=true"
import script from "./bof-filing-view.vue?vue&type=script&lang=js"
export * from "./bof-filing-view.vue?vue&type=script&lang=js"
import style0 from "./bof-filing-view.vue?vue&type=style&index=0&id=63890054&prod&lang=scss"
import style1 from "./bof-filing-view.vue?vue&type=style&index=1&id=63890054&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63890054",
  null
  
)

export default component.exports