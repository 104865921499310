<template>
  <div v-if="hasPermission">
    <h2 class="header">{{serviceDisplayName}}</h2>
    <div class="box">
      <div class="col-md-12">
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="price">ZenBusiness Price *</label>
              <select v-model="price" class="form-control" name="Price" id="price" required>
                <option value = "0">$0</option>
                <option value = "100">$100</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="fees">State Fees *</label>
              <input v-model="fees" class="form-control" type="number" name="Fees" id="fees" required/>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="service-description">Additional Details *</label>
              <input v-model="productData.order_data.service_description" class="form-control" placeholder="Include any relevant detail of this service." name="ServiceDescription" id="service-description" required
                maxlength="250"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-else>
    Your account doesn't have the permissions required to place an order for this product.
  </div>
</template>
<script>
import { orderCreateProductMixin } from '@/components/mixins';

export default {
  name: 'dissolution',

  props: ['pricing', 'jurisdiction', 'serviceName', 'serviceDisplayName', 'hasPermission'],

  mixins: [orderCreateProductMixin],

  data() {
    return {
      productData: {
        order_data: {
          service: this.serviceName,
          jurisdiction: this.jurisdiction,
          formation_jurisdiction: this.jurisdiction
        },
        order_item: {
          service: this.serviceName,
          jurisdiction: this.jurisdiction,
          price: 0,
          fees: 0
        }
      }
    };
  },
  computed: {
    isValid: function () {
      const validations = []
      validations.push(this.price !== "" && this.price >= 0)
      validations.push(this.fees !== "")
      validations.push(this.productData.order_data.service_description && this.productData.order_data.service_description.length > 2 && this.productData.order_data.service_description.length <= 250 )
      return validations.every(Boolean)
    }
  }
};
</script>
